.blue-box {
  padding: 120px 80px 150px;
  color: #fff;
  border-radius: 30px;
  background-color: #0054a6;
  margin-bottom: 140px;

  @include respond-max('desktop') {
    padding: 60px;
    margin-bottom: 110px;
  }
  
  @include respond-max('tablet') {
    padding: 40px 20px 20px;
    margin-bottom: 40px;
  }

  .section-title {
    color: #fff;
  }

  &__grid {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr;

    @include respond-max('desktop') {
      display: flex;
      flex-direction: column;
    }

    @include respond-max('tablet') {
      gap: 16px;
    }
  }

  &__layer {
    flex: 1;
    color: #000;
    display: flex;
    flex-direction: column;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #ccc;
    background: #fff;

    @include respond-max('desktop') {
      width: 100%;
      min-height: 768px;
    }
    
    @include respond-max('tablet') {
      padding: 20px;
      min-height: 570px;
    }
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -1.08px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__icon {
    margin-bottom: 50px;

    @include respond-max('tablet') {
      margin-bottom: 26px;
    }
  }

  &__image {
    height: 210px;
    margin-bottom: 20px;

    @include respond-max('desktop') {
      height: 335px;
      margin-bottom: 55px;
    }

    @include respond-max('tablet') {
      height: 230px;
      margin-bottom: 20px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__section {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -0.84px;
    margin-bottom: 40px;
  }

  .button {
    margin-top: auto;

    @include respond-max('desktop') {
      margin-top: 90px;
    }

    @include respond-max('tablet') {
      margin-top: 30px;
    }
  }
}
