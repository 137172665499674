.tools-box {
  margin-bottom: 160px;

  @include respond-max('desktop') {
    margin-bottom: 110px;
  }
  
  @include respond-max('tablet') {
    margin-bottom: 16px;
  }

  &__grid {
    display: flex;
    justify-content: center;
    gap: 30px;

    @include respond-max('tablet') {
      width: 100%;
      flex-direction: column;
    }

    @include respond-max('tablet') {
      gap: 16px;
    }
  }

  &__layer {
    width: 585px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid #d3d3d3;

    @include respond-max('desktop') {
      width: 278px;
    }

    @include respond-max('tablet') {
      width: 100%;
    }
  }

  &__title {
    max-width: 410px;
    min-height: 94px;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -1.08px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  p {
    font-size: 28px;
    font-weight: 400;
    letter-spacing: -0.84px;
    margin-bottom: 40px;
  }

  .button {
    margin-top: auto;

    @include respond-max('tablet') {
      margin-top: 30px;
    }
  }
}